<script>
export default {}
</script>

<template>
  <div :class="$style.backButton" v-on="$listeners"></div>
</template>

<style lang="scss" module>
@import '@design';
.backButton {
  display: flex;
  align-items: center;
  width: 46px;
  height: 46px;
  margin: 0 10px 0 0;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill-rule='evenodd'%3e%3cpath fill='%23283549' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23EEEEEE' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 7px;

  // hover animation
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: 0.3s cubic-bezier(0.23, 1.02, 0.28, 1.12);
  transform: scaleX(-1);

  &:hover,
  &:focus {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill-rule='evenodd'%3e%3cpath fill='%23354255' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23EEEEEE' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.11);
    transition: 0.3s cubic-bezier(0.23, 1.02, 0.28, 1.12);
    transform: scale(-1.1, 1.1);
  }
}
</style>
